@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url(./assets/fonts/Gilroy-Regular.otf);
}

@font-face {
  font-family: 'Gilroy-light';
  src: local('Gilroy-light'), url(./assets/fonts/Gilroy-Light.otf);
}

@font-face {
  font-family: 'Gilroy-extra-bold';
  src: local('Gilroy-extra-bold'), url(./assets/fonts/Gilroy-ExtraBold.otf);
}

@font-face {
  font-family: 'Gilroy-bold';
  src: local('Gilroy-bold'), url(./assets/fonts/Gilroy-Bold.ttf);
}

@font-face {
  font-family: 'Gilroy-semi-bold';
  src: local('Gilroy-semi-bold'), url(./assets/fonts/Gilroy-SemiBold.ttf);
}

body {
  margin: 0;
  font-family: 'Gilroy', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #444;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


html {
  scroll-behavior: smooth;
  min-width: 100%;
  min-height: 100%;
  font-size: 10px;
}

strong {
  font-family: 'Gilroy-semi-bold';
  font-weight: normal;
}

body > .MuiDrawer-root {
  height: fit-content;
}

@media (min-width: 300px) {
  html {
    font-size: 9px;
  }
}

@media (min-width: 400px) {
  html {
    font-size: 10px;
  }
}

@media (min-width: 768px) {
  html {
    font-size: 11px;
  }
}

@media (min-width: 1520px) {
  html {
    font-size: 11px;
  }
}

/** MUI Overrides **/
.MuiOutlinedInput-root {
  background-color: white;
}

input.MuiInputBase-input, textarea.MuiInputBase-input {
  font-size: 16px;
  line-height: 25px;
}

a {
  color: unset;
  text-decoration: unset;
}

.MuiPickersCalendarHeader-labelContainer .MuiPickersCalendarHeader-label {
  font-size: 1.6rem;
}

.MuiPickersDay-root.MuiPickersDay-dayWithMargin {
  font-size: 1.3rem;
}

.MuiDayPicker-header .MuiDayPicker-weekDayLabel, .MuiDayCalendar-header .MuiDayCalendar-weekDayLabel {
  font-size: 1.3rem;
}

@media(max-width: 600px) {
  .fixed-button-bottom {
    background: white;
    position: fixed !important;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    padding-left: 16px;
    padding-right: 16px;
    left: 0;
    padding: 20px 16px;
    border-top: solid 1px rgba(53, 46, 26, 0.25);
    background: width;
    z-index: 2;
  }
}

.hide-modal-wrapper .MuiPaper-root { 
  background: transparent;
  box-shadow: none;
}

.pac-container {
  z-index: 1500 !important;
}